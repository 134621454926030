<script setup>
</script>

<!--
 template内にhtml,head,bodyは含まない
 -->
<template>
  <div class="container my-container">
    <div class="mt-4 mb-4">
      <slot />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.my-container {
  margin: 0 auto;
  width: 100%;
  max-width: 576px;
}
</style>
